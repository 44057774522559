$press-container-width: toRem(1300);
$press-resources-width: toRem(1060);
$press-container-gap: toRem(150);
$press-carousel-btn-size: toRem(16);
$press-resources-icon-height: toRem(30);

.press {

	&-hero {
		padding-block: $gap-xxl;
		background-color: $maroon;

		@include breakpoint(tablet) {
			padding-block: $gap-xl;
		}

		@include breakpoint(mobile) {
			padding-block: $gap;
		}

		&__container {
			@extend %container;
			max-width: $press-container-width;
			@include flex(space-between, flex-start, $press-container-gap);

			@include breakpoint(laptop) {
				gap: $gap-xl;
			}

			@include breakpoint(tablet) {
				flex-direction: column;
			}

			@include breakpoint(mobile) {
				gap: $gap-lg;
			}
		}

		&__copy {
			flex: 2;
			color: $white;

			.tag {
				@extend %tag;
				color: $gold;
			}
		}

		&__links {
			flex: 1;
			@include flex(flex-start, flex-start, $gap-sm);
			flex-direction: column;
		}
	}

	&-carousel {

		&__head {
			@extend %container;
			position: relative;
			max-width: $press-container-width;
		}

		&__nav {
			@include flex(flex-end, center, $gap-xs);
			position: absolute;
			top: 50%;
			right: 0;
			transform: translateY(-50%);

			&__prev,
			&__next {
				@include button-reset;
				width: $press-carousel-btn-size;
				height: $press-carousel-btn-size;
				background-size: contain;
				background-repeat: no-repeat;
				overflow: hidden;

				&.swiper-button-disabled {
					opacity: .3;
				}
			}

			&__prev{
				background-image: url('./../images/icons/press-carousel-prev.svg');
				left: var(--swiper-navigation-sides-offset, 20px)
			}

			&__next {
				background-image: url('./../images/icons/press-carousel-next.svg');
			}
		}

		&__container {
			@extend %container;
			max-width: $press-container-width;
		}

		&__image {
			aspect-ratio: 1.7 / 1;

			img {
				@include object-fit;
			}
		}

		&__media {
			padding: $gap $gap-lg;
			background-color: $bg-gray;
		}
	}

	&-resources {

		&__head {
			@extend %container;
			max-width: $press-container-width;

			.max-width {
				max-width: $press-resources-width;
			}
		}

		&__list {
			@extend %container;
			@include flex(space-between, stretch, $gap);
			max-width: $press-container-width;

			@include breakpoint(mobile) {
				flex-direction: column;
				gap: $gap-lg;
			}
		}

		&__item {
			@include flex(space-between, flex-start, $gap);
			flex-direction: column;
			flex: 1;

			@include breakpoint(mobile) {
				gap: $gap-xs;
			}
		}

		&__icon {
			height: $press-resources-icon-height;
			background-size: contain;
			background-position: center left;
			background-repeat: no-repeat;

			&.experts {
				background-image: url('./../images/icons/press-experts.jpg');
			}

			&.facts {
				background-image: url('./../images/icons/press-facts.jpg');
			}

			&.contact {
				background-image: url('./../images/icons/press-contact.jpg');
			}
		}
	}
}