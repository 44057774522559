// breakpoints
$breakpoints: (
	"mobile": "500px",
	"tablet": "768px",
	"laptop": "1024px",
	"desktop": "1440px",
	"max": "100rem",
	"widescreen": "1920px",
);

@mixin breakpoint($width, $breakpoints: $breakpoints) {

	@if map-has-key($breakpoints, $width) {
		$width: map-get($breakpoints, $width);
	}

	@media screen and (max-width: #{$width}) {
		@content;
	}
}