.flex {
	&.magazine {
		h1,
		.h1-style {
			@extend %h1-mag;
			font-weight: 700;
		}

		h2,
		.h2-style {
			@extend %h2-mag;
		}

		.h2-border {
			@extend %h2-border;
		}

		h3,
		.h3-style {
			@extend %h3-mag;
		}

		h4,
		.h4-style {
			@extend %h4-mag;
		}

		blockquote {
			@include font-head-mag;
		}
	}

	&-hero {
		position: relative;
		aspect-ratio: 1.7 / 1;
		background-color: $maroon;

		@include breakpoint(tablet) {
			aspect-ratio: 1.5 / 1;
		}

		@include breakpoint(mobile) {
			aspect-ratio: 1 / 1;
		}

		&.no-image {
			aspect-ratio: unset;

			@include breakpoint(tablet) {
				aspect-ratio: unset;
			}

			@include breakpoint(mobile) {
				aspect-ratio: unset;
			}

			.flex-hero__copy {
				padding-block: $gap-xxl;

				@include breakpoint(laptop) {
					padding-block: $gap-xl;
				}

				@include breakpoint(tablet) {
					padding-block: $gap-lg;
				}

				@include breakpoint(mobile) {
					padding-block: $gap;
				}
			}
		}

		&__copy {
			@include container(toRem(800));
			@include flex(flex-end, center, 0);
			flex-direction: column;
			position: relative;
			height: 100%;
			text-align: center;
			color: $white;
			padding-bottom: $gap-xxl;
			z-index: 2;

			@include breakpoint(laptop) {
				padding-bottom: $gap-xl;
			}

			@include breakpoint(tablet) {
				padding-bottom: $gap-lg;
			}

			@include breakpoint(mobile) {
				padding-bottom: $gap;
			}

			.author {
				font-style: italic;
			}
		}

		&__overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: $gradient-black;
			z-index: 1;
		}

		&__image {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				@include object-fit;
			}
		}
	}

	&-content {
		@extend %container;
		position: relative;

		@include breakpoint(mobile) {
			width: 100%;
		}

		.react-photo-album--photo {
			@extend %zoom;
			transform: scale(1);
			transition: transform .6s ease;

			&:hover {
				transform: scale(1.035);
			}
		}
	}

	&-share {
		position: absolute;
		top: 0;
		left: 0;

		@include breakpoint(mobile) {
			position: relative;
			margin-inline: $gap-sm;
		}

		&__icons {
			@include flex(flex-start, stretch, 0);
			flex-direction: column;
			border: 1px solid $border-gray;

			@include breakpoint(mobile) {
				flex-direction: row;
				border: 0;
			}
		}

		&__link {
			display: block;
			border-bottom: 1px solid $border-gray;

			@include breakpoint(mobile) {
				border-top: 1px solid $border-gray;
				border-left: 1px solid $border-gray;
				padding-inline: $gap-xs;
			}

			img {
				display: block;
				width: $gap-sm;
				margin-inline: auto;
				padding-block: $gap-xs;
			}

			&:last-child {
				border-bottom: 0;

				@include breakpoint(mobile) {
					border-bottom: 1px solid $border-gray;
					border-right: 1px solid $border-gray;
				}
			}
		}
	}
}
