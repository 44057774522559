$footer-margin-top: toRem(135);
$footer-top-bottom-padding: toRem(100);
$footer-logo-width: toRem(190);

.footer {
	text-align: center;
	margin-top: $footer-margin-top;
	padding-top: $gap-lg;
	background-color: $bg-gray;

	&__top {
		@include flex(space-between, center, $gap);
		padding-bottom: $footer-top-bottom-padding;

		@include breakpoint(laptop) {
			@include flex(center, stretch, $gap-sm);
			flex-direction: column;
		}

		@include breakpoint(tablet) {
			@include flex(center, center, $gap);
			padding-bottom: $gap-lg;
		}
	}

	&__logo {
		width: $footer-logo-width;

		img {
			display: block;
			width: 100%;
		}
	}

	&__links {
		@include flex(space-between, center, $gap);

		@include breakpoint(tablet) {
			@include flex(center, center, $gap);
			flex-direction: column;
		}
	}

	&__nav {
		@include flex(flex-start, center, $gap-sm);
		list-style: none;
		padding: 0;

		@include breakpoint(mobile) {
			flex-direction: column;
		}

		.link-black {
			@include font(16, 24);
	
			@include breakpoint(mobile) {
				@include font(18, 24);
			}
		}
	}

	&__social {
		@include flex(flex-end, center, $gap-sm);
		list-style: none;
		padding: 0;

		.social-link {
			display: block;
			height: $gap;

			@include breakpoint(tablet) {
				height: $gap-sm;
			}

			&:hover {
				img {
					transform: scale(1.2);
				}
			}

			img {
				@include object-fit(contain, center);
				transform: scale(1);
				transition: transform .3s ease;
			}
		}
	}

	&__bottom {
		border-top: 1px solid $border-gray;

		&__container {
			@include flex(space-between, center, $gap);
			padding-block: $gap-xs;

			@include breakpoint(mobile) {
				@include flex(flex-start, center, 0);
				flex-direction: column;
			}
		}
	}

	&__utility {
		@include flex(flex-end, center, $space);
		list-style: none;
		padding: 0;
	}
}