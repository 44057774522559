$no-image-max-height: 350px;

.block-related {
	&__list {
		position: relative;
		z-index: 1;

		@include breakpoint(mobile) {
			margin-top: $gap-sm;
		}

		&__container {
			@include flex(space-between, stretch, $gap-sm);

			@include breakpoint(mobile) {
				flex-direction: column;
			}
		}

		&__item {
			flex: 1;

			&.no-image {
				padding: $gap;
				background-color: $bg-gray;
				max-height: $no-image-max-height;

				@include breakpoint(mobile) {
					padding: $gap-sm;
				}
			}

			&__image {
				
				img {
					width: 100%;
				}
			}
		}
	}
}