%btn {
	@include button-reset;
	@include font-bold;
	@include button-reset;
	position: relative;
	display: inline-block;
	text-transform: uppercase;
	text-decoration-color: transparent;
	padding: $gap-xs $gap-xl $gap-xs $gap;
	border-radius: 0;
	appearance: none;
	transition: text-decoration-color .3s ease;

	@include breakpoint(tablet) {
		padding: $gap-xs $gap-lg $gap-xs $gap-sm;
	}

	&:hover {
		text-decoration-color: inherit;

		&::after {
			right: $gap-sm;
		}
	}

	&::after {
		position: absolute;
		top: 50%;
		right: $gap;
		content: '';
		display: inline-block;
		width: $button-icon-size;
		height: $button-icon-size;
		margin-top: 1px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		transform: translateY(-50%);
		transition: right .3s ease;

		@include breakpoint(tablet) {
			right: $gap-sm;
		}
	}

	&:hover {
		cursor: pointer;
	}
}

.btn {

	&-red {
		@extend %btn;
		color: $white;
		background-color: $maroon;

		&::after {
			background-image: url('/images/icons/btn-arrow-white.svg');
		}
	}

	&-gold {
		@extend %btn;
		color: $text-black;
		background-color: $gold;

		&::after {
			background-image: url('/images/icons/btn-arrow-black.svg');
		}
	}

	&-wide {
		display: block;
	}
}

.video-btn {
	@include button-reset;

	svg {
		display: block;
		width: $video-btn-svg-size;
		height: $video-btn-svg-size;
		pointer-events: none;
		transform: scale(1);
		transition: transform .3s ease;

		@include breakpoint(tablet) {
			width: $video-btn-svg-size-tablet;
			height: $video-btn-svg-size-tablet;
		}

		@include breakpoint(mobile) {
			width: $video-btn-svg-size-mobile;
			height: $video-btn-svg-size-mobile;
		}
	}

	&:hover {
		svg {
			transform: scale(1.2);
		}
	}
}