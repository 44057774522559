.tag-maroon {
	@extend %tag;
	color: $maroon;
}

.tag-maroon-small {
	@extend %tag;
	@include font(12, 16);
	color: $maroon;
}

.tag-gold {
	@extend %tag;
	color: $white; 


	&::after {
		content: '';
		display: inline-block;
		width: 40px;
		height: 4px;
		margin: 0 0 2px $gap-xs;
		background-color: $gold;
	}
}

.tag-gold-underline {
	@extend %tag; 
	position: relative;
	display: inline-block;
	padding-bottom: $gap-xs;
	color: $white;

	&::after {
		position: absolute;
		bottom: 0;
		left: 0;
		content: '';
		display: inline-block;
		width: 100%;
		height: 4px;
		background-color: $gold;
	}
}

.tag-gold-long {
	@extend %tag; 
	color: $white; 

	
	&::after {
		content: '';
		display: inline-block;
		width: 120px;
		height: 4px;
		margin: 0 0 2px $gap-xs;
		background-color: $gold;
	}
}

.tag-black {
	@extend %tag;
	color: $black;
}

.tag-mag-white {
	@extend %tag;
	display: inline-block;
	color: $text-black !important;
	padding: $sliver $space;
	background-color: $white;
}

.tag-mag-gray {
	@extend %tag;
	display: inline-block;
	color: $text-black;
	padding: $sliver $space;
	background-color: $bg-gray;
}