*,
*:before,
*:after {
	box-sizing: inherit;
}

html {
	box-sizing: border-box;
	scroll-behavior: smooth;
}

body {
	@include font-reg;
	@include font(16, 24);
	color: $text-black;
	scroll-behavior: smooth;
}

.scrolled + .main {
	transition: all .3s ease-in-out;
	padding-top: $header-height;
}

.main {
	transition: all .3s ease-in-out;
	padding-top: $header-height-total;
	overflow: hidden;

	@include breakpoint(laptop) {
		padding-top: $header-height-total-laptop;
	}

	@include breakpoint(mobile) {
		padding-top: $header-height-total-mobile;
	}
}



h1,
.h1-style {
	@extend %h1;

	a {
		@extend %head-link;
	}
}

h2,
.h2-style {
	@extend %h2;

	a {
		@extend %head-link;
	}
}

%h2-border,
.h2-border {
	@extend %p-large;
	@include font-bold;
	text-transform: uppercase;
	padding-bottom: $gap-xs;
	margin-bottom: $gap-lg;
	border-bottom: 1px solid $border-gray;
}

h3,
.h3-style {
	@extend %h3;

	a {
		@extend %head-link;
	}
}

h4,
.h4-style {
	@extend %h4;

	a {
		@extend %head-link;
	}
}

h5,
.h5-style {
	@extend %h5;

	a {
		@extend %head-link;
	}
}

p, li {
	@extend %p;
}

.p-small {
	@extend %p-small;
}

.p-large {
	@extend %p-large;
}

.bold {
	@include font-bold;
}

.uppercase {
	text-transform: uppercase
}

.head-border-white {
	@extend %head-border;
	border-color: $white;
}

.head-border-black {
	@extend %head-border;
	border-color: $black;
}

// magazine only 

.h1-style-mag {
	@extend %h1-mag;

	a {
		@extend %head-link;
	}
}

.h2-style-mag {
	@extend %h2-mag;

	a {
		@extend %head-link;
	}
}

.h2-border-mag {
	@extend %p-large;
	@include font-bold;
	text-transform: uppercase;
	padding-bottom: $gap-xs;
	margin-bottom: $gap;
	border-bottom: 1px solid $border-gray;
}

.h3-style-mag {
	@extend %h3-mag;

	a {
		@extend %head-link;
	}
}

.h4-style-mag {
	@extend %h4-mag;
	@extend %head-link;

	a {
		@extend %head-link;
	}
}

// misc 
.max-width {
	max-width: $max-width;
}

// Screen reader only text
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}