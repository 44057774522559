// Arnhem

@font-face {
    font-family: 'arnhem_probold';
    src: url('../fonts/arnhem/arnhempro-bold-webfont.woff2') format('woff2'),
         url('../fonts/arnhem/arnhempro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'arnhem_problond';
    src: url('../fonts/arnhem/arnhempro-blond-webfont.woff2') format('woff2'),
         url('../fonts/arnhem/arnhempro-blond-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'arnhem_problack';
    src: url('../fonts/arnhem/arnhempro-black-webfont.woff2') format('woff2'),
         url('../fonts/arnhem/arnhempro-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

// Antenna

@font-face {
    font-family: 'antennacondlight';
    src: url('../fonts/antenna/antennacond-light-webfont.woff2') format('woff2'),
         url('../fonts/antenna/antennacond-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'antennacondbold';
    src: url('../fonts/antenna/antennacond-bold-webfont.woff2') format('woff2'),
         url('../fonts/antenna/antennacond-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'antennacondblack';
    src: url('../fonts/antenna/antennacond-black-webfont.woff2') format('woff2'),
         url('../fonts/antenna/antennacond-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansbold';
    src: url('../fonts/opensans/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans/opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'open_sansregular';
    src: url('../fonts/opensans/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans/opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}