.block-expert {
	@include flex(space-between, flex-start, $gap-lg);

	@include breakpoint(tablet) {
		gap: $gap;
	}

	@include breakpoint(mobile) {
		flex-direction: column;
	}

	&__image {
		flex: .7;

		img {
			width: 100%;
		}
	}

	&__copy {
		flex: 1.3;

		.h2-border {
			margin-bottom: 0;
		}

		&__expertise {
			@include flex(flex-start, flex-start, $gap-sm);
			list-style: none;
			margin-bottom: $gap-sm;
			padding: $gap-xs 0;
			border-bottom: 1px solid $border-gray;
		}
	}
}