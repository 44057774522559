$quote-icon-height: toRem(38);

.block-quote {
	position: relative;
	margin-bottom: $gap-xl;
	padding-block: $gap-xl;
	border-top: 1px solid $border-gray;
	border-bottom: 1px solid $border-gray;

	@include breakpoint(mobile) {
		padding-block: $gap-lg;
	}

	&::before,
	&::after {
		position: absolute;
		content: '';
		display: block;
		width: $gap-xxl;
		height: $quote-icon-height;
		background-color: $white;
		background-size: contain;
		background-repeat: no-repeat;
		z-index: 1;
	}

	&::before {
		top: -$gap-sm;
		left: 0;
		content: '';
		background-image: url('./../images/icons/blockquote-start.svg');
		background-position: center left;
	}

	&::after {
		bottom: -$gap-xs;
		right: 0;
		content: '';
		background-image: url('./../images/icons/blockquote-end.svg');
		background-position: center right;
	}

	blockquote {
		color: $maroon;
	}
}