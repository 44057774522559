$hero-list-top-margin: toRem(68);

.magazine {
	&-hero {
		&__container {
			@extend %container;
		}

		&__feature {
			position: relative;

			&.in-view {
				.magazine-hero__feature__image {
					transform: translateY(0);
				}

				.magazine-hero__feature__copy__container {
					transform: translateX(-$gap-sm);

					@include breakpoint(mobile) {
						transform: translate(0, 0);
					}
				}
			}

			&__container {
				@include flex(space-between, stretch, 0);

				@include breakpoint(mobile) {
					flex-direction: column;
				}
			}

			&__image {
				position: relative;
				flex: 1;
				transform: translateY($gap);
				transition: transform 1s ease;
				overflow: hidden;

				@include breakpoint(mobile) {
					margin-inline: -$gap;
				}

				img {
					@include object-fit;
				}
			}

			&__copy {
				position: relative;
				flex: 1;

				@include breakpoint(mobile) {
					margin-top: -$gap-xl;
				}

				&__container {
					position: relative;
					margin-block: $gap-sm;
					padding: $gap $gap-xl calc($gap + $hero-list-top-margin) $gap-xxl;
					background-color: $bg-gray;
					transform: translateX(0);
					transition: transform 1s ease;

					@include breakpoint(tablet) {
						padding: $gap $gap calc($gap-xs + $hero-list-top-margin) $gap-lg;
					}

					@include breakpoint(mobile) {
						margin-block: 0;
						padding: $gap-sm;
						transform: translate(0, $gap-sm);
					}

					&::after {
						position: absolute;
						top: 0;
						right: -$gap-sm;
						content: "";
						display: block;
						width: $gap-sm;
						height: 100%;
						background-color: $bg-gray;

						@include breakpoint(mobile) {
							display: none;
						}
					}
				}
			}
		}

		&__list {
			position: relative;
			margin-top: -$hero-list-top-margin;
			z-index: 1;

			@include breakpoint(mobile) {
				margin-top: $gap-sm;
			}

			&__container {
				@include flex(space-between, stretch, $gap-sm);
				padding-inline: $gap;

				@include breakpoint(tablet) {
					gap: $gap-xs;
					padding-inline: $gap-sm;
				}

				@include breakpoint(mobile) {
					flex-direction: column;
					gap: $gap-sm;
					padding-inline: 0;
				}
			}

			&__item {
				flex: 1;
				padding-bottom: $gap;
				transform: translateY($gap-xxl);
				transition: transform 1s ease;

				@include breakpoint(mobile) {
					padding-bottom: 0;
					transform: translateY($gap-lg);
				}

				&.in-view {
					transform: translateY(0);
				}

				&.no-image {
					@include flex(space-between, stretch, $gap-xs);
					flex-direction: column;
					padding: $gap;
					background-color: $gold;

					@include breakpoint(mobile) {
						padding: $gap-sm;
					}
				}

				&__image {
					aspect-ratio: 1.6 / 1;
					overflow: hidden;

					@include breakpoint(mobile) {
						aspect-ratio: 1.8 / 1;
					}

					&.in-view {
						img {
							transform: scale(1.1);
						}
					}

					img {
						@include object-fit;
						transform: scale(1);
						transition: transform 0.9s ease;

						@include breakpoint(mobile) {
							transition-delay: 0;
						}
					}
				}
			}
		}
	}

	&-people {
		position: relative;

		&__title {
			@extend %container;
		}

		&__feature {
			@include flex(space-between, stretch, 0);
			position: relative;

			@include breakpoint(mobile) {
				flex-direction: column;
			}

			&.in-view {
				.magazine-people__feature__copy,
				.magazine-people__feature__image {
					transform: translateY(0);
				}
			}

			&__copy {
				flex: 0.66;
				color: $white;
				padding: $gap-xxl;
				background-color: $maroon;
				transform: translateY($gap);
				transition: transform 1s ease;

				@include breakpoint(tablet) {
					padding: $gap-lg;
				}

				@include breakpoint(mobile) {
					order: 2;
					margin: -$gap $gap-sm $gap $gap-sm;
					padding: $gap;
				}
			}

			&__image {
				flex: 1.33;
				border-top: $gap solid $white;
				border-bottom: $gap solid $white;
				transform: translateY(-$gap);
				transition: transform 1s ease;
				overflow: hidden;

				@include breakpoint(mobile) {
					order: 1;
					border: 0;
					transform: translateY(0);
				}

				img {
					@include object-fit;
				}
			}
		}

		&__list {
			@extend %container;
			@include flex(space-between, stretch, $gap-sm);
			position: relative;
			margin-top: -$gap-lg;
			z-index: 1;

			@include breakpoint(mobile) {
				flex-direction: column;
				margin-top: 0;
			}

			&__item {
				flex: 1;
				transform: translateY($gap-lg);
				transition: transform 1s ease;

				@include breakpoint(mobile) {
					@include flex(space-between, flex-start, $gap-xs);
				}

				&.in-view {
					transform: translateY(0);
				}

				&__image {
					aspect-ratio: 2.5 / 3;
					overflow: hidden;

					img {
						@include object-fit;
					}
				}
			}
		}
	}

	&-video {
		position: relative;
		width: calc(100% - ($container-padding * 2));
			max-width: $container-width;
			margin-inline: auto;

		@include breakpoint(max) {
			width: unset;
			max-width: unset;
			margin-inline: unset;
			
		}

		&__head {
			@extend %container;
		}

		&__feature {
			position: relative;

			&__overlay {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				aspect-ratio: 16 / 9;
				z-index: 1;

				&.hidden {
					display: none;
				}

				&__image {
					height: 100%;
					overflow: hidden;

					img {
						@include object-fit;
					}
				}

				&__button {
					position: absolute;
					top: 50%;
					left: 50%;
					width: $video-btn-svg-size;
					height: $video-btn-svg-size;
					transform: translate(-50%, -50%);
					z-index: 4;
				}
			}

			&__video {
				@extend %container;
				position: relative;
				z-index: -1;

				iframe {
					width: 100%;
					aspect-ratio: 16 / 9;
				}
			}

			&__tag {
				position: absolute;
				top: -$space;
				left: $gap-xxl;

				@include breakpoint(tablet) {
					left: $gap-xl;
				}

				@include breakpoint(mobile) {
					left: $gap;
				}
			}

			&__copy {
				@extend %container-narrow;
				position: relative;
				margin-top: -$gap-xl;
				padding: $gap-lg $gap-xxl;
				background-color: $gold;
				transform: translateY($gap-lg);
				transition: transform 0.6s ease;
				z-index: 2;

				@include breakpoint(tablet) {
					padding: $gap $gap-xl;
				}

				@include breakpoint(mobile) {
					padding: $gap $gap-sm;
				}

				&.in-view {
					transform: translateY(0);
				}
			}
		}

		&__list {
			@extend %container;
			@include flex(space-between, flex-start, $gap-sm);

			@include breakpoint(mobile) {
				flex-direction: column;
			}
		}

		&__item {
			flex: 1;

			&__image {
				aspect-ratio: 1.8 / 1;
				overflow: hidden;

				img {
					@include object-fit;
				}
			}
		}
	}

	&-archive {
		&__head {
			@extend %container;
		}

		&__container {
			position: relative;

			&::after {
				position: absolute;
				top: $gap-xxl;
				left: $gap-lg;
				content: "";
				display: block;
				width: calc(100% - ($gap-lg * 2));
				height: calc(100% - ($gap-xxl * 2));
				background-color: $bg-gray;
				z-index: -1;

				@include breakpoint(tablet) {
					top: $gap-lg;
					left: $gap-xs;
					width: calc(100% - ($gap-xs * 2));
					height: calc(100% - ($gap-lg * 2));
				}

				@include breakpoint(mobile) {
					left: 0;
					width: 100%;
				}
			}
		}

		&__list {
			@extend %container;
			@include flex(justify-content, flex-start, $gap-sm);

			@include breakpoint(tablet) {
				flex-wrap: wrap;
			}
		}

		&__item {
			flex: 1;

			@include breakpoint(mobile) {
				flex: 0 0 calc(50% - ($gap-sm / 2));
			}

			&__image {
				aspect-ratio: 1 / 1.2;
				overflow: hidden;

				img {
					@include object-fit;
				}
			}
		}

		&__cta {
			@include flex(center, center, 0);
			transform: translateY($gap-lg);
			transition: transform 0.6s ease;

			&.in-view {
				transform: translateY(0);
			}

			&__container {
				display: inline-block;
				width: auto;
				color: $white;
				padding: $gap;
				background-color: $maroon;

				@include breakpoint(tablet) {
					padding: $gap $gap-lg;
				}

				@include breakpoint(mobile) {
					text-align: center;
					padding: $gap-sm $gap;
				}

				.h3-style-mag {
					padding-right: $gap-sm;

					@include breakpoint(mobile) {
						display: block;
						margin-bottom: $gap-xs;
						padding-right: 0;
					}
				}
			}
		}
	}
}
