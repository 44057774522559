$dropdown-arrow-width: 14px;
$dropdown-arrow-height: 9px;
$stories-item-width: calc(33% - $gap-sm);

.stories {
    .main {
        overflow: visible;
    }
    &__filter {
        margin-block-start: $gap-xxl;
        @include breakpoint(tablet) {
            margin-block: $gap;
        }
        #filterForm {
            align-items: center;
            @include breakpoint(tablet) {
                flex-direction: column;
            }
            .tag-black {
                width: 15%;
                @include breakpoint(mobile) {
                    width: unset;
                }
            }
            .filter-dropdowns {
                margin-left: $gap-xxl;
                width: 100%;

                @include breakpoint(tablet) {
                    margin-left: unset;
                    gap: $gap-xs;
                }

                @include breakpoint(mobile) {
                    flex-direction: column;
                }

                .custom-dropdown {
                    position: relative;
                    width: 100%;

                    &::after {
                        display: inline;
                        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none"><path d="M1.65083 0L7 5.56507L12.3492 0L14 1.71746L7 9L0 1.71746L1.65083 0Z" fill="%23181D1C"/></svg>');
                        position: absolute;
                        right: $gap-xs;
                        top: $gap-xs;
                        width: $dropdown-arrow-width;
                        height: $dropdown-arrow-height;
                        pointer-events: none;
                        transition: all 0.3s ease-in-out;
                    }

                    &.active {
                        &::after {
                            transform: rotate(180deg) translateY(-14px);
                        }

                        .dropdown-options {
                            display: block;
                        }
                    }

                    .dropdown-header {
                        cursor: pointer;
                        padding: $gap-xs;
                        background-color: $bg-gray;
                        border: none;
                        width: 100%;
                        min-height: 48px;
                    }

                    .dropdown-options {
                        display: none;
                        position: absolute;
                        width: 100%;
                        background-color: $white;
                        padding: unset;
                        z-index: 100;
                        list-style: none;
                        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);

                        li {
                            cursor: pointer;
                            padding: $gap-xs;
                            &:hover {
                                background-color: $bg-gray;
                            }

                            &.current {
                                background-color: $bg-gray;
                                font-weight: 700;

                                &::after {
                                    display: inline;
                                    content: url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.3332 4L5.99984 11.3333L2.6665 8" stroke="%23181D1C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
                                    position: absolute;
                                    right: $gap-sm;
                                    margin-top: $sliver;
                                    pointer-events: none;

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__list {
        @include flex(center, flex-start, $gap-sm);
        row-gap: $gap-xxl;
        flex-wrap: wrap;
        &__item {
            flex: 0 0 $stories-item-width;
            padding-bottom: $gap;
            transform: translateY($gap-xxl);
            transition: transform 1s ease;

            @include breakpoint(tablet) {
                flex: 1 1 100%;
                padding-bottom: 0;
                transform: translateY($gap-lg);
            }

            &.hidden {
                display: none;
            }

            .item-title {
                @extend %h4;
                @include font(25, 30);
            }

            &.in-view {
                transform: translateY(0);
            }

            &.no-image {
                @include flex(space-between, stretch, $gap-xs);
                flex-direction: column;
                padding: $gap;
                background-color: $gold;

                @include breakpoint(mobile) {
                    padding: $gap-sm;
                }
            }

            &__image {
                margin-bottom: $space;
                img {
                    @include object-fit(contain, center);
                    transform: scale(1);
                    transition: transform 0.9s ease;

                    @include breakpoint(mobile) {
                        transition-delay: 0;
                    }
                }
            }
        }
        .btn-gold {
            padding: $gap-sm $gap $gap-sm $gap;
            margin-block: $gap-xl;
            &::after {
                display: none;
            }

            &.disabled {
                opacity: 0.6;
                pointer-events: none;

            }
        }
    }


}
