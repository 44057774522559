a {

	img {
		text-decoration: none;
	}
}

%link {
	text-decoration-color: transparent;
	text-decoration-thickness: .05em;
	transition: text-decoration-color .3s ease;

	&:hover {
		text-decoration-color: inherit;
		cursor: pointer;
	}
}

.link-black {
	@extend %link;
	color: $black;
}

.link-white {
	@extend %link;
	color: $white;
}

%arrow-link {
	@extend %link;
	@extend %p-large;
	@include font-bold;
	display: inline-block;
	text-transform: uppercase;

	&:hover {		
		&::after {
			transform: translate($gap-sm, 2px);
		}
	}

	&::after {
		content: '';
		display: inline-block;
		width: $button-icon-size;
		height: $button-icon-size;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		transform: translate($gap-xs, 2px);
		transition: transform .3s ease;
	}
}

.arrow-link-black {
	@extend %arrow-link;
	color: $black;

	&::after {
		background-image: url('/images/icons/btn-arrow-black.svg');
	}
}

.arrow-link-white {
	@extend %arrow-link;
	color: $white;

	&::after {
		background-image: url('/images/icons/btn-arrow-white.svg');
	}
}

%zoom {
	display: block;
	overflow: hidden;
	
	&:hover {
		img {
			cursor: pointer;
		}
	}

	img {
		text-decoration: none;
		transform: scale(1);
		transition: transform .6s ease;
	}	
}

.image-zoom {	
	@extend %zoom;

	&:hover {
		img {
			transform: scale(1.035);
		}
	}
}

.icon-zoom {
	@extend %zoom;

	&:hover {
		img {
			transform: scale(1.3);
		}
	}
}

