// px to rems
$base-font-size: 16;

@function toRem($value) {
    $remValue: math.div($value, $base-font-size) + rem; 
    @return $remValue;
}

// fonts 
@mixin font-head {
	font-family: 'antennacondbold', sans-serif;
	font-weight: normal;
}

@mixin font-reg {
	font-family: 'open_sansregular', sans-serif;
	font-weight: normal;
}

@mixin font-bold {
	font-family: 'open_sansbold', sans-serif;
	font-weight: normal;
}

// magazine head 
@mixin font-head-mag {
	font-family: 'arnhem_problond', sans-serif;
	font-weight: normal;
}

// custom container width 
@mixin container($width) {
	@extend %container;
	max-width: $width;
}

// object fit
@mixin object-fit($type: cover, $position: center) {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: $type;
	object-position: $position;
}

// flexbox
@mixin flex($justify: space-between, $align: flex-start, $gap: 0) {
	display: flex;
	justify-content: $justify;
	align-items: $align;
	gap: $gap;
}

@mixin font($size, $line-height) {
	font-size: toRem($size);
	line-height: calc($line-height / $size);
}

// button reset
@mixin button-reset {
	padding: 0;
	background-color: transparent;
	border: 0;
	border-radius: 0;
	text-shadow: none;
	box-shadow: none;
	appearance: none;

	&:hover {
		cursor: pointer;
	}
}
