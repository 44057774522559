// main site

%h1 {
	@include font-head;
	@include font(70, 75);

	@include breakpoint(laptop) {
		@include font(50, 54);
	}

	@include breakpoint(tablet) {
		@include font(35, 38);
	}

	@include breakpoint(mobile) {
		@include font(27, 30);
	}
}

%h2 {
	@include font-head;
	@include font(50, 54);

	@include breakpoint(laptop) {
		@include font(35, 38);
	}

	@include breakpoint(tablet) {
		@include font(27, 30);
	}
}

%h3 {
	@include font-head;
	@include font(35, 38);

	@include breakpoint(laptop) {
		@include font(27, 30);
	}

	@include breakpoint(tablet) {
		@include font(22, 25);
	}
}

%h4 {
	@include font-head;
	@include font(27, 30);

	@include breakpoint(laptop) {
		@include font(22, 25);
	}

	@include breakpoint(tablet) {
		@include font(18, 22);
	}
}

%h5 {
	@include font-head;
	@include font(18, 22);

	@include breakpoint(tablet) {
		@include font(16, 20);
	}
}

%p {
	@include font-reg;
	@include font(16, 24);
}

%p-small {
	@include font-reg;
	@include font(14, 24);
}

%p-large {
	@include font-reg;
	@include font(18, 28);
}

%p-xl {
	@include font-reg;
	@include font(24, 36);

	@include breakpoint(tablet) {
		@include font(18, 28);
	}
}

blockquote {
	@include font-head;
	@include font(40, 48);

	@include breakpoint(tablet) {
		@include font(36, 42);
	}

	@include breakpoint(mobile) {
		@include font(24, 28);
	}
}

%tag {
	@include font-bold;
	@include font(14, 20);
	text-transform: uppercase;


	@include breakpoint(tablet) {
		@include font(12, 16);
	}
}

a%tag {
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

// magazine

%h1-mag {
	@include font-head-mag;
	@include font(60, 70);

	@include breakpoint(laptop) {
		@include font(45, 53);
	}

	@include breakpoint(tablet) {
		@include font(35, 42);
	}

	@include breakpoint(mobile) {
		@include font(25, 30);
	}
}

%h2-mag {
	@include font-head-mag;
	@include font(45, 53);

	@include breakpoint(laptop) {
		@include font(35, 42);
	}

	@include breakpoint(tablet) {
		@include font(25, 30);
	}
}

%h3-mag {
	@include font-head-mag;
	@include font(35, 42);

	@include breakpoint(tablet) {
		@include font(25, 30);
	}
}

%h4-mag {
	@include font-head-mag;
	@include font(25, 30);

	@include breakpoint(tablet) {
		@include font(20, 23);
	}
}

%head-border {
	padding-bottom: $gap-xs;
	border-bottom: 1px solid;
}

%head-link {
	color: inherit;
	text-decoration-color: transparent;
	text-decoration-thickness: .05em;
	transition: text-decoration-color .3s ease;
	
	&:hover {
		text-decoration-color: inherit;
	}
}
