.block {
	position: relative;
	padding-block: $gap-lg;
	padding-inline: $gap-lg;

	@include breakpoint(mobile) {
		padding-inline: $gap-sm;
	}

	&-inner {
		@extend %container;

		&-narrow {
			@extend %container-narrow;
		}

		&-wysiwyg {
			@extend %container-wysiwyg
		}
	}

	&-flex {
		@include flex(space-between, flex-start, $gap);
	}
}