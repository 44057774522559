// block spacing (css class, desktop spacing, mobile spacing)
$stacks: (
	"xxxl" toRem(120) toRem(64),
	"xxl" toRem(80) toRem(48),
	"xl" toRem(48) toRem(40),
	"lg" toRem(32) toRem(32),
	"reg" toRem(24) toRem(24),
	"sm" toRem(16) toRem(16),
	"xs" toRem(8) toRem(8),
	"xxs" toRem(4) toRem(4)
);

// top/bottom spacing between elements	
@each $size, $value, $mobile-value in $stacks {
	.stack-#{$size} > * + * {
		margin-block-start: #{$value};

		@media screen and (max-width: 768px) {
			margin-block-start: #{$mobile-value};
		}
	}
}
