$home-hero-featured-padding-top: toRem(200);
$home-hero-featured-padding-top-tablet: (160);
$home-hero-item-padding-top: toRem(120);
$home-hero-feature-transition: 1.5s ease 0.25s;
$home-hero-item-1-transition: 1.5s ease 0.75s;
$home-hero-item-2-transition: 1.5s ease 1.25s;

$lens-head-padding-block: toRem(140);
$lens-head-bg-height: 125px;
$lens-head-bg-height-tablet: 75px;
$lens-item-padding-top: toRem(160);

$home-video-arrow-width: toRem(110);

$experts-head-padding: toRem(90);
$experts-head-padding-bottom: toRem(150);

.home {
	&-hero {
		overflow: hidden;

		&__container {
			@extend %container;
			@include flex(space-between, flex-start, $gap-lg);
			overflow: hidden;
			width: calc(100% - ($container-padding));

			@include breakpoint(max) {
				margin-inline: 0;
			}

			@include breakpoint(tablet) {
				flex-direction: column;
			}

			@include breakpoint(mobile) {
				gap: $gap-sm;
				align-items: stretch;
			}
		}

		&__featured {
			position: relative;
			flex: 3.75;
			@include breakpoint(desktop) {
				flex: 2.75;
			}

			&.in-view {
				.home-hero__featured__container {
					transform: translateY(0);
				}

				&::after {
					width: calc(100% - $gap-lg);
				}
			}

			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				content: "";
				display: block;
				width: 0;
				height: 50%;
				background-color: $maroon;
				transition: width $home-hero-feature-transition;
				z-index: -1;
			}

			&__container {
				position: relative;
				margin-bottom: $gap-lg;
				transform: translateY(25vh);
				transform-origin: bottom left;
				transition: $home-hero-feature-transition;

				@include breakpoint(tablet) {
					margin-bottom: $gap-lg;
					transform: translateY($gap-xl);
				}

				@include breakpoint(mobile) {
					margin-bottom: $gap;
				}
			}

			&__copy {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: $home-hero-featured-padding-top $gap-xxl $gap-lg $gap-xxl;
				background: $gradient-black;

				@include breakpoint(laptop) {
					padding: $home-hero-featured-padding-top $gap-lg $gap-lg $gap-lg;
				}

				@include breakpoint(tablet) {
					padding: $home-hero-featured-padding-top-tablet $gap-lg $gap-lg $gap-lg;
				}

				@include breakpoint(mobile) {
					padding: $gap-xxl $gap-sm $gap-sm $gap-sm;
				}

				&__container {
					max-width: $max-width;

					h2,
					p {
						color: $white;
					}
				}
			}

			&__image {
				margin-bottom: $gap-xl;
				overflow: hidden;

				@include breakpoint(mobile) {
					margin-bottom: $gap;
				}

				img {
					display: block;
					width: 100%;
					transform: scale(1);
					transition: 0.9s ease;
				}
			}
		}

		&__items {
			flex: 1.25;
			@include flex(flex-start, flex-end, $gap-sm);
			flex-direction: column;
			margin-top: $gap-sm;

			@include breakpoint(tablet) {
				flex-direction: row;
				margin-top: 0;
			}

			@include breakpoint(mobile) {
				flex-direction: column;
				align-items: stretch;
			}

			&.in-view {
				.home-hero__item {
					transform: translateY(0);
				}
			}
		}

		&__item {
			position: relative;
			overflow: hidden;
			transform: translateY(25vh);
			transition: transform $home-hero-item-1-transition;

			@include breakpoint(tablet) {
				transform: translateY($gap-xl);
				transition-delay: 0;
			}

			&:last-child {
				transition: transform $home-hero-item-2-transition;

				@include breakpoint(tablet) {
					transition-delay: 0;
				}
			}

			&__image {
				img {
					display: block;
					width: 100%;
					transform: scale(1);
					transition: 0.9s ease;
					transition-delay: 0.3s;
				}
			}

			&__copy {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: $home-hero-item-padding-top $gap-lg $gap $gap-lg;
				background: $gradient-black;

				@include breakpoint(laptop) {
					padding: $home-hero-item-padding-top $gap $gap $gap;
				}

				@include breakpoint(mobile) {
					padding: $gap-xxl $gap-sm $gap-sm $gap-sm;
				}

				&__container {
					max-width: $max-width;

					h2,
					p {
						color: $white;
					}
				}
			}
		}
	}

	&-news {
		&__container {
			@extend %container;
			@include flex(space-between, flex-start, $gap-lg);

			@include breakpoint(tablet) {
				gap: $gap-sm;
			}

			@include breakpoint(mobile) {
				flex-direction: column;
				align-items: stretch;
			}
		}

		&__main {
			flex: 2.75;
			padding-top: $gap-xl;

			@include breakpoint(tablet) {
				flex: 2.5;
			}

			&__item {
				padding-bottom: $gap;
				margin-block-start: $gap;
				border-bottom: 1px solid $border-gray;

				&__container {
					@include flex(space-between, flex-start, $gap);

					@include breakpoint(tablet) {
						flex-direction: column;
						align-items: stretch;
					}
				}

				&__image {
					flex: 1;
					aspect-ratio: 1.5 / 1;
					overflow: hidden;

					@include breakpoint(tablet) {
						aspect-ratio: 2 / 1;
					}

					img {
						@include object-fit;
						transform: scale(1);
						transition: 0.9s ease;
					}
				}

				&__copy {
					flex: 1;

					p {
						color: $text-gray;
					}

					.tag {
						color: $maroon;
					}
				}
			}
		}

		&__sidebar {
			flex: 1.25;

			@include breakpoint(tablet) {
				flex: 1.5;
			}

			&__media {
				padding: $gap-xl $gap-lg;
				margin-bottom: $gap-xxl;
				background-color: $bg-gray;

				@include breakpoint(laptop) {
					margin-bottom: unset;

				}
				@include breakpoint(tablet) {
					padding: $gap $gap-sm;
				}

				h2 {
					padding-bottom: $gap-xs;
					border-bottom: 1px solid $border-gray;
				}

				&__item {
					padding-bottom: $gap-sm;
					border-bottom: 1px solid $border-gray;

					&:last-child {
						padding-bottom: 0;
						border-bottom: 0;
					}
				}
			}

			&__mag {
				// padding: $gap;
				// background-color: $gold;
				position: relative;

				@include breakpoint(tablet) {
					padding: $gap-sm;
				}

				&__image {
					overflow: hidden;
					box-shadow: 17px 19px 25px -4px rgba(0, 0, 0, 0.51);

					img {
						display: block;
						width: 100%;
					}
				}

				.btn-wide {
					margin-top: $gap;
				}
			}
		}
	}

	&-lens {
		position: relative;
		background-color: $bg-gray;
		overflow: hidden;

		&.in-view {
			.home-lens__content__item {
				transform: translateY(0);
			}
		}

		&::after {
			position: absolute;
			bottom: 0;
			left: 0;
			content: "";
			display: block;
			width: 100%;
			height: $lens-head-bg-height;
			background-color: $white;

			@include breakpoint(tablet) {
				height: $lens-head-bg-height-tablet;
			}

			@include breakpoint(mobile) {
				display: none;
			}
		}

		&__head {
			position: relative;
			width: $max-width;
			margin-inline: auto;
			padding-block: $lens-head-padding-block $gap-lg;
			z-index: 1;

			@include breakpoint(tablet) {
				padding-block: $gap-xl $gap;
			}

			@include breakpoint(mobile) {
				max-width: 100%;
				padding: $gap-lg $gap-sm $gap-sm $gap-sm;
			}
		}

		&__content {
			position: relative;
			z-index: 1;

			@include breakpoint(mobile) {
				padding-bottom: $gap-lg;
			}

			&__container {
				@extend %container;
				@include flex(space-between, flex-end, $gap);

				@include breakpoint(mobile) {
					flex-direction: column;
				}
			}

			&__item {
				position: relative;

				&.in-view {
					.home-lens__content__item__container {
						transform: translateY(0);

						@include breakpoint(mobile) {
							transform: translate(0, 0);
						}
					}
				}

				&:first-child {
					margin-bottom: $gap-xl;

					@include breakpoint(mobile) {
						margin-bottom: 0;
					}
				}

				&:nth-child(2) {
					.home-lens__content__item__container {
						transition-delay: 0.6s;

						@include breakpoint(mobile) {
							transition-delay: 0;
						}
					}
				}

				&:last-child {
					margin-bottom: $gap-xxl;

					@include breakpoint(mobile) {
						margin-bottom: 0;
					}

					.home-lens__content__item__container {
						transition-delay: 1.2s;

						@include breakpoint(mobile) {
							transition-delay: 0;
						}
					}
				}

				&__container {
					position: relative;
					aspect-ratio: 1 / 1.75;
					transform: translateY(100vh);
					transition: transform 1.2s ease;
					overflow: hidden;

					@include breakpoint(mobile) {
						aspect-ratio: 1 / 1;
						transform: translate(100vw, 0);
					}
				}

				&__image {
					@include object-fit;
				}

				&__copy {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					padding: $lens-item-padding-top $gap-lg $gap-lg $gap-lg;
					background: $gradient-black;

					@include breakpoint(mobile) {
						padding: $lens-item-padding-top $gap $gap $gap;
					}

					p {
						position: relative;
						color: $white;
						padding-top: $gap-xs;

						&::before {
							position: absolute;
							top: 0;
							left: 0;
							content: "";
							display: block;
							width: $gap-xl;
							height: $sliver;
							background-color: $gold;
						}

						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}
	}

	&-video {
		position: relative;
		background-color: $maroon;
		padding-top: $gap-xxl;

		@include breakpoint(mobile) {
			padding-top: $gap;
		}

		&__title {
			@extend %container;
			max-width: 70%;

			@include breakpoint(laptop) {
				max-width: 77%;
			}

			@include breakpoint(mobile) {
				max-width: 100%;
			}

			h2 {
				color: $white;
			}
		}

		&__carousel {
			@include breakpoint(mobile) {
				padding-inline: $gap-lg;
			}

			.swiper {
				&-wrapper {
					padding-bottom: $gap-lg;
				}

				&-button-prev,
				&-button-next {
					top: 0;
					width: $home-video-arrow-width;
					height: 100%;
					margin-top: 0;
					z-index: 99;
					opacity: 0;
					transition: opacity 0.3s ease;

					@include breakpoint(mobile) {
						width: $gap-lg;
						opacity: 1;
					}

					&::after {
						font-size: $gap;
						color: $white;

						@include breakpoint(mobile) {
							font-size: $gap-sm;
						}
					}

					&:hover {
						opacity: 1;
					}
				}

				&-button-prev {
					left: var(--swiper-navigation-sides-offset, 120px);
				}
				&-button-next {
					right: var(--swiper-navigation-sides-offset, 120px);
				}

				&-pagination {
					bottom: 0;

					&-bullet {
						background: $white;
						margin-inline: $gap-xs;

						&-active {
							background: $gold;
						}
					}
				}
			}
		}

		&__slide {
			position: relative;
			width: 100%;
			height: 60vh;
			overflow: hidden;

			@include breakpoint(mobile) {
				height: auto;
				aspect-ratio: 16 / 9;
			}

			&__container {
				position: relative;
				width: 100%;
				height: 100%;
				z-index: 1;

				&.hidden {
					display: none;
				}
			}

			&__button {
				@include button-reset;
				position: absolute;
				bottom: 0;
				left: 0;
				@include flex(flex-end, flex-end, 0);
				width: calc(100% - ($gap-lg * 2));
				height: calc(100% - ($gap-lg * 2));
				padding: $gap-lg;
				z-index: 1;

				@include breakpoint(tablet) {
					width: calc(100% - ($gap * 2));
					height: calc(100% - ($gap * 2));
					padding: $gap;
				}

				@include breakpoint(mobile) {
					width: calc(100% - ($gap-sm * 2));
					height: calc(100% - ($gap-sm * 2));
					padding: $gap-sm;
				}
			}

			&__image {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: -1;

				img {
					@include object-fit;
				}
			}

			&__copy {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				width: calc(100% - ($gap-lg * 2));
				height: calc(100% - ($gap-lg * 2));
				padding: $gap-lg;
				background: $gradient-black-75;

				@include breakpoint(tablet) {
					width: calc(100% - ($gap * 2));
					height: calc(100% - ($gap * 2));
					padding: $gap;
				}

				@include breakpoint(mobile) {
					width: calc(100% - ($gap-sm * 2));
					height: calc(100% - ($gap-sm * 2));
					padding: $gap-sm;
				}

				h2,
				p {
					max-width: $max-width-narrow;
					color: $white;

					@include breakpoint(laptop) {
						// 100% - (button width + padding)
						max-width: calc(100% - ($video-btn-svg-size + ($gap-lg * 2)));
					}

					@include breakpoint(tablet) {
						max-width: calc(100% - ($video-btn-svg-size-tablet + ($gap * 2)));
					}

					@include breakpoint(mobile) {
						max-width: calc(100% - ($video-btn-svg-size-mobile + ($gap-sm * 2)));
					}
				}

				.video-desc {
					@include breakpoint(mobile) {
						display: none;
					}
				}
			}
		}

		&__video {
			position: relative;
			padding-bottom: 56.25%;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				display: block;
				width: 100%;
				height: 100%;
			}
		}

		&__cta {
			text-align: center;
			transform: translateY($gap);

			@include breakpoint(tablet) {
				transform: translateY($gap-sm);
			}
		}
	}

	&-experts {
		@include breakpoint(tablet) {
			background-color: $bg-gray;
		}

		&__container {
			@extend %container;
			@include flex(space-between, stretch, 0);

			@include breakpoint(tablet) {
				flex-direction: column;
			}
		}

		&__head {
			flex: 1.25;
			padding-block: $experts-head-padding $experts-head-padding-bottom;
			padding-inline: $experts-head-padding;
			background-color: $bg-gray;

			@include breakpoint(laptop) {
				padding-block: $gap-xl $experts-head-padding;
				padding-inline: $gap-xl;
			}

			@include breakpoint(tablet) {
				padding: $gap-xl $gap;
			}

			@include breakpoint(mobile) {
				padding: $gap-lg 0;
			}
		}

		&__list {
			position: relative;
			flex: 2.75;
			padding-block: $gap-lg;

			@include breakpoint(tablet) {
				padding: 0 $gap $gap-xl $gap;
			}

			@include breakpoint(mobile) {
				padding-inline: 0;
			}

			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				display: block;
				width: 20%;
				height: 100%;
				background-color: $bg-gray;

				@include breakpoint(tablet) {
					display: none;
				}
			}
		}

		&__item {
			position: relative;
			@include flex(space-between, flex-start, $gap-sm);
			z-index: 1;

			@include breakpoint(mobile) {
				flex-direction: column;
			}

			&:first-child {
				margin-bottom: $gap-lg;
				padding-bottom: $gap-lg;
				border-bottom: 1px solid $border-gray;
			}

			&__image {
				flex: 1;
				aspect-ratio: 1 / 1;

				img {
					@include object-fit;
					transform: scale(1.1);
					transition: 0.9s ease;
				}
			}

			&__copy {
				flex: 2.5;

				@include breakpoint(laptop) {
					flex: 2;
				}

				@include breakpoint(tablet) {
					flex: 2.5;
				}

				.bio {
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 5;
					overflow: hidden;
				}
			}
		}
	}
}
