$search-icon-size: 25px;

.search {
    &-bar {
        @include flex(center, center, $gap-lg);
        margin-block: $gap-xl;
    }
    &__container {
        @include flex(space-between, center, $gap-lg);
        background-color: $bg-gray;
        width: 100%;

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            width: $search-icon-size;
            height: $search-icon-size;
            margin-left: $gap-xs;
            background-image: url("/images/search/search-icon.svg");
            background-repeat: no-repeat;
        }

        .search-input {
            border: none;
            background: none;
            height: 100%;
            width: 100%;
            margin-left: $gap-lg;
        }

        .btn-red {
            padding: $gap-xs $gap;
            margin: $space;

            &::after {
                display: none;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &-results {
        .result {
            padding: $gap 0;
            border-bottom: 1px solid $border-gray-transparent;

            &-image__container {
                width: 100%;
                height: 100%;

                img,
                picture {
                    @include object-fit;
                }
            }

            &-text__container {
                .tag-maroon-small {
                    // margin-bottom: $gap-xs;
                }

                h4 {
                    // margin-bottom: $gap-xs;
                }

                .description {
                    color: $text-gray;
                }
            }
        }
    }
}
