$header-logo-width: 435px;
$header-logo-width-desktop: 300px;
$header-logo-width-laptop: 250px;
$header-logo-width-mobile: 180px;

$header-subnav-width: 150px;

.magazine {

	.header {

		&__logo a {
			display: none;

			&.magazine {
				display: block;
			}
		}
	}
}

.stories, .search {
	.header {
		box-shadow: 0px 0px 4px rgba(#000, .04), 0px 8px 16px rgba(#000, .08);

		@include breakpoint(mobile) {
			box-shadow: 0px 0px 2px rgba(#000, .04), 0px 4px 8px rgba(#000, .08);
		}

	}
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	background-color: $white;
	transition: top .3s ease-in-out;
	z-index: 999;

	&.scrolled {
		top: -$header-utility-height;
		box-shadow: 0px 0px 4px rgba(#000, .04), 0px 8px 16px rgba(#000, .08);

		@include breakpoint(mobile) {
			top: -$header-utility-height-mobile;
			box-shadow: 0px 0px 2px rgba(#000, .04), 0px 4px 8px rgba(#000, .08);
		}
	}

	&__utility {
		height: $header-utility-height;
		border-bottom: 1px solid $border-gray;

		@include breakpoint(mobile) {
			height: $header-utility-height-mobile;
		}

		&__container {
			@extend %container;
			@include flex(flex-end, center, 0);
			padding-block: 18px 14px;

			@include breakpoint(mobile) {
				padding-block: 10px;
			}
		}

		&__logo {

			img {
				display: block;
				width: auto;
				height: 35px;

				@include breakpoint(mobile) {
					height: 25px;
				}
			}
		}
	}

	&__container {
		@extend %container;
		@include flex(space-between, flex-end, 0);
		height: $header-height;
		padding-bottom: $gap;

		@include breakpoint(laptop) {
			align-items: center;
			height: $header-height-laptop;
			padding-bottom: $gap-xs;
		}

		@include breakpoint(mobile) {
			height: $header-height-mobile;
		}
	}

	&__logo {
		display: flex;
		align-items: center;
		flex: 0 0 $header-logo-width;

		@include breakpoint(desktop) {
			flex: 0 0 $header-logo-width-desktop;
		}

		@include breakpoint(laptop) {
			flex: 0 0 $header-logo-width-laptop;
		}

		@include breakpoint(mobile) {
			flex: 0 0 $header-logo-width-mobile;
		}

		a {
			text-decoration: none;
			text-transform: uppercase;

			&.magazine {
				display: none;
			}

			img,
			svg {
				display: block;
				width: 100%;
			}
		}
	}

	&__mobile-toggle {
		display: none;

		@include breakpoint(laptop) {
			display: block;
		}

		.nav-toggle {
			@include button-reset;
			font-size: toRem(18);
			line-height: 36px;
			position: relative;
			line-height: $header-height;
			text-transform: uppercase;
			color: $black;
			padding-right: $gap-lg;

			@include breakpoint(laptop) {
				line-height: $header-height-laptop;
			}

			@include breakpoint(mobile) {
				font-size: toRem(16);
				line-height: $header-height-mobile;
				padding-right: $gap;
			}

			&[aria-pressed="true"] {
				.show-menu {
					display: none;
				}

				.hide-menu {
					display: inline-block;
				}
			}

			&[aria-pressed="false"] {
				.show-menu {
					display: inline-block;
				}

				.hide-menu {
					display: none;
				}
			}

			svg {
				position: absolute;
				top: 50%;
				width: $gap-sm;
				height: auto;
				margin-left: $gap-xs;
				transform: translateY(-50%);
				pointer-events: none;

				&.show-menu {
					right: 0;
				}

				&.hide-menu {
					right: 10px;

					@include breakpoint(mobile) {
						width: $gap-xs;
					}
				}
			}
		}
	}

	&__nav {
		position: relative;

		@include breakpoint(laptop) {
			position: absolute;
			top: $header-height-total-laptop;
			left: 0;
			width: 100vw;
			height: 0;
			background-color: $white;
			overflow: hidden;
			transition: height 0.3s ease;
			z-index: 999;
		}

		@include breakpoint(mobile) {
			top: $header-height-total-mobile;
		}

		&[aria-hidden="false"] {
			@include breakpoint(laptop) {
				height: calc(100vh - #{$header-height-laptop});
				overflow: auto;
			}

			@include breakpoint(mobile) {
				height: calc(100vh - #{$header-height-mobile});
			}
		}

		&[aria-hidden="true"] {
			@include breakpoint(laptop) {
				height: 0;
				overflow: hidden;
			}
		}

		&__container {
			@include flex(flex-end, flex-end, $gap-lg);
			width: 100%;
			margin: 0;
			padding: 0;

			@include breakpoint(laptop) {
				display: block;
				text-align: center;
				padding: $gap-lg;
			}
		}

		.nav-item {
			position: relative;
			@include flex(flex-start, center);
			list-style-type: none;
			margin: 0;
			padding-bottom: $gap-xs;
			transition: height 0.3s ease;

			@include breakpoint(laptop) {
				display: block;
				text-align: left;
				padding-bottom: $gap;

			}

			.subnav-toggle {
				@include button-reset;

				&>* {
					pointer-events: none;
				}

				.arrow {
					display: inline-block;
					margin-left: $gap-xs;
					transform: translateY(0) scaleY(-1);
					transform-origin: center;

					@include breakpoint(laptop) {
						transform: translateY(-1px) scaleY(-1);
					}
				}

				&:hover {
					cursor: pointer;
				}

				&[aria-pressed="false"] {
					.arrow {
						transform: translateY(-3px) scaleY(1);

						@include breakpoint(laptop) {
							transform: translateY(-1px) scaleY(1);
						}
					}
				}

				&[aria-pressed="true"] {
					background-color: $white;
					border-bottom: 8px solid $white;

					@include breakpoint(laptop) {
						background-color: $white;
						border-bottom: 1px solid $white;
					}

					&::after {
						transform: scaleY(1) translateY(-8px);
					}
				}
			}

			.nav-link {
				position: relative;
				text-decoration: underline;
				text-decoration: none;
				color: $black;
				border-bottom: 8px solid transparent;
				transition: border-color 0.3s ease;

				&::before {
					position: absolute;
					bottom: -$sliver;
					left: 0;
					content: '';
					display: block;
					width: 100%;
					height: 2px;
					background-color: transparent;
					transition: background-color .3s ease;
				}

				&:hover {
					&::before {
						background-color: $maroon;
					}
				}

				&.current {
					@include font-bold;

					&:hover {
						&::before {
							display: none;
						}
					}
				}

				&.search {

					&::after {
						content: '';
						display: inline-block;
						width: 13px;
						height: 13px;
						margin-left: $space;
						background-image: url('/images/icons/search.svg');
					}
				}
			}
		}

		.nav-subnav {
			position: absolute;
			top: 30px;
			left: -$gap-sm;
			width: $header-subnav-width;
			height: 0;
			margin: 0;
			padding: 0 $gap-sm;
			background-color: $white;
			transition: height 0.3s ease;
			overflow: hidden;
			box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.08), 0px 0px 4px 0px rgba(0, 0, 0, 0.04);


			@include breakpoint(laptop) {
				position: relative;
				top: unset;
				margin: 0;
				padding: 0;
				z-index: unset;
			}

			@include breakpoint(tablet) {
				left: $gap;
				box-shadow:unset;

			}

			.subnav-item {
				list-style-type: none;
				margin: 0;

				@include breakpoint(laptop) {
					text-align: left;
				}

				&:first-child {
					padding-top: $gap-xs;
				}

				&:last-child {

					.subnav-link {

						@include breakpoint(laptop) {
							padding-bottom: 0;
						}
					}
				}

				.subnav-link {
					display: block;
					color: $black;
					text-decoration: none;
					padding-block: 0 $gap-sm;
					background-color: $white;

					@include breakpoint(laptop) {
						padding-block: $gap-xs;
					}

					&:hover {
						text-shadow:1px 0 0 $black;
					}
				}
			}
		}
	}
}