// spacing
$gap-xxl: toRem(80);
$gap-xl: toRem(60);
$gap-lg: toRem(48);
$gap: toRem(32);
$gap-sm: toRem(24);
$gap-xs: toRem(12);
$space: toRem(8);
$sliver: toRem(4);

// header
$header-height: 135px;
$header-height-laptop: 100px;
$header-height-mobile: 65px;
$header-utility-height: 65px;
$header-utility-height-mobile: 45px;
$header-height-total: calc(#{$header-utility-height} + #{$header-height});
$header-height-total-laptop: calc(#{$header-utility-height} + #{$header-height-laptop});
$header-height-total-mobile: calc(#{$header-utility-height-mobile} + #{$header-height-mobile});

// misc
$max-width: toRem(650);
$max-width-narrow: toRem(550);
$button-icon-size: toRem(16);

//video
$video-btn-svg-size: $gap-xxl;
$video-btn-svg-size-tablet: $gap-lg;
$video-btn-svg-size-mobile: $gap;