// brand
$maroon: #8D0034;
$gold: #FEBC18;

// general
$black: #202A25;
$white: #FFF;

$text-black: #181D1C;
$text-gray: #555555;

$bg-gray: #EEEEEE;
$border-gray: #BBBBBB;
$border-gray-transparent: rgba(187, 187, 187, 0.6);

$gradient-black: linear-gradient(180deg, rgba(#000, 0) 0%, rgba(#000, 1) 100%);
$gradient-black-75: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 75%);