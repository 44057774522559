// container widths 
$container-width: toRem(1600);
$container-width-medium: toRem(900);
$container-width-narrow: toRem(700);
$container-width-wysiwyg: toRem(600);

$container-padding: $gap-xxl;
$container-padding-laptop: $gap-lg;
$container-padding-tablet: $gap;
$container-padding-mobile: $gap-sm;

// containers
%container,
.container {
	width: calc(100% - ($container-padding * 2));
	max-width: $container-width;
	margin-inline: auto;

	@include breakpoint(laptop) {
		width: calc(100% - ($container-padding-laptop * 2));
	}

	@include breakpoint(tablet) {
		width: calc(100% - ($container-padding-tablet * 2));
	}

	@include breakpoint(mobile) {
		width: calc(100% - ($container-padding-mobile * 2));
	}
}	

%container-medium,
.container-medium {
	@extend %container;
	max-width: $container-width-medium;
}

%container-narrow,
.container-narrow {
	@extend %container;
	max-width: $container-width-narrow;
}

%container-wysiwyg,
.container-wysiwyg {
	@extend %container;
	max-width: $container-width-wysiwyg;

	@include breakpoint(tablet) {
		width: calc(100% - ($container-padding * 2));
	}

	@include breakpoint(mobile) {
		width: calc(100% - ($container-padding-mobile * 2));
	}
}
